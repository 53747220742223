<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="signUpObserver" tag="form" @submit.prevent>
      <NTypeform v-show="!displayRelog" class="typeform" :currentSlide="currentSlide">
        <template v-slot:slides>
          <NTypeformSlide ref="emailSlide" :index="1">
            <template>
              <NText
                data-cy="input-email"
                ref="input-email"
                id="input-email"
                :name="$t('register.form.email').toLowerCase()"
                type="email"
                v-model="signupForm.model.sEmail"
                :rules="`required|email|alreadyRegistered:${user_already_exist}`"
                animationType="above"
                errorTrigger="blur"
                :textColor="colorStyle.quaternary"
                :labelColor="colorStyle.quaternary"
                placeholder="jean@gmail.com"
                :backgroundColor="colorStyle.quaternary"
                :showIcons="false"
                :designType="panoplyStyle.inputs.designType"
                :borderColor="colorStyle.quinary"
                :errorColor="colorStyle.error"
                @isValid="(isValid) => (validity.email = isValid)"
              >What is your email address?</NText>
              <NTypeformCta data-cy="cta-next" v-if="validity.email" @click.native="nextSlideDedup()">{{$t('ok')}}</NTypeformCta>
            </template>
          </NTypeformSlide>

          <NTypeformSlide :index="2">
            <template>
              <NRadios
                data-cy="input-civility"
                id="iCivilId"
                :name="$t('register.form.gender').toLowerCase()"
                :rules="dedupStatus.isDedup ? '' : 'required'"
                v-model="signupForm.model.iCivilId"
                designType="box"
                textColor="white"
                :displayError="false"
                labelColor="white"
                labelPosition="above"
                borderType="squared"
                borderColor="white"
                :selectedBorderColor="colorStyle.quinary"
                radioColor="white"
                backgroundColor="transparent"
                :selectedColor="'white'"
                selectedBackgroundColor="transparent"
                :values="{'Mr': 1, 'Mrs': 2}"
                :errorColor="colorStyle.error"
                @isValid="(isValid) => (validity.gender = isValid)"
              >How should we address you?</NRadios>
              <NTypeformCta data-cy="cta-next" v-if="validity.gender" @click.native="nextSlide()">{{$t('ok')}}</NTypeformCta>
            </template>
          </NTypeformSlide>

          <NTypeformSlide :index="3">
            <template>
              <NText
                data-cy="input-firstname"
                id="input-firstname"
                :name="$t('register.form.firstname').toLowerCase()"
                type="text"
                v-model="signupForm.model.sFirstName"
                :rules="dedupStatus.isDedup ? '' : 'required|nameLatin'"
                animationType="above"
                errorTrigger="blur"
                :textColor="colorStyle.quaternary"
                :labelColor="colorStyle.quaternary"
                placeholder="Firstname"
                :backgroundColor="colorStyle.quaternary"
                :showIcons="false"
                :designType="panoplyStyle.inputs.designType"
                :borderColor="colorStyle.quinary"
                :errorColor="colorStyle.error"
                @isValid="(isValid) => (validity.firstname = isValid)"
              >What is your first name?</NText>
              <NTypeformCta data-cy="cta-next" v-if="validity.firstname" @click.native="nextSlide()">{{$t('ok')}}</NTypeformCta>
            </template>
          </NTypeformSlide>

          <NTypeformSlide :index="4">
            <template>
              <NText
                data-cy="input-lastname"
                id="input-lastname"
                :name="$t('register.form.lastname').toLowerCase()"
                type="text"
                v-model="signupForm.model.sLastName"
                :rules="dedupStatus.isDedup ? '' : 'required|nameLatin'"
                animationType="above"
                errorTrigger="blur"
                :textColor="colorStyle.quaternary"
                :labelColor="colorStyle.quaternary"
                placeholder="Lastname"
                :backgroundColor="colorStyle.quaternary"
                :showIcons="false"
                :designType="panoplyStyle.inputs.designType"
                :borderColor="colorStyle.quinary"
                :errorColor="colorStyle.error"
                @isValid="(isValid) => (validity.lastname = isValid)"
              >What is your last name?</NText>
              <NTypeformCta data-cy="cta-next" v-if="validity.lastname" @click.native="nextSlide()">{{$t('ok')}}</NTypeformCta>
            </template>
          </NTypeformSlide>

          <NTypeformSlide :index="5">
            <template>
              What country are you living in?
              <br><br>
              <v-select :options="countryList" label="label" v-model="signupForm.model.oResponses.iQuestion_103" :reduce="country => country.code"></v-select>
              <br><br>
              <NTypeformCta data-cy="cta-next" v-if="signupForm.model.oResponses.iQuestion_103" @click.native="nextSlideCountry()">{{$t('ok')}}</NTypeformCta>
            </template>
          </NTypeformSlide>

          <NTypeformSlide :index="6">
            <template>
              <NMask
                data-cy="input-zipcode"
                id="input-zipcode"
                :name="$t('register.form.zipcode').toLowerCase()"
                maskType="text"
                :mask="signupForm.model.oResponses.iQuestion_103 == 230 ? '### ###' : 'XXXXXX'"
                :rules="getPostalCodeRule"
                v-model="signupForm.model.iZipcode"
                animationType="above"
                errorTrigger="blur"
                :textColor="colorStyle.quaternary"
                :labelColor="colorStyle.quaternary"
                placeholder="Postal code"
                :backgroundColor="colorStyle.quaternary"
                :showIcons="false"
                :designType="panoplyStyle.inputs.designType"
                :borderColor="colorStyle.quinary"
                :errorColor="colorStyle.error"
                @isValid="(isValid) => (validity.zipcode = isValid)"
              >What's your zip code?</NMask>
              <NTypeformCta data-cy="cta-next" v-if="validity.zipcode" @click.native="nextSlide()">{{$t('ok')}}</NTypeformCta>
            </template>
          </NTypeformSlide>

          <NTypeformSlide :index="7">
            <template>
              <NRadios
                data-cy="input-decant"
                id="decant"
                :name="$t('register.form.decant').toLowerCase()"
                rules="required"
                v-model="signupForm.model.oResponses.iQuestion_102"
                designType="box"
                :displayError="false"
                textColor="white"
                labelColor="white"
                labelPosition="above"
                borderType="squared"
                borderColor="white"
                :selectedBorderColor="colorStyle.quinary"
                radioColor="white"
                backgroundColor="transparent"
                :selectedColor="'white'"
                selectedBackgroundColor="transparent"
                :values="{'Yes': 1, 'No': 2}"
                :errorColor="colorStyle.error"
                @isValid="(isValid) => (validity.decant = isValid)"
              >Do you own one of our decanters?</NRadios>
              <NTypeformCta data-cy="cta-next" v-if="validity.decant" @click.native="nextSlideDecant()">{{$t('ok')}}</NTypeformCta>
            </template>
          </NTypeformSlide>

          <NTypeformSlide ref="decantNumberSlide" :index="8">
            <template>
              <NMask
                data-cy="input-decantnumber"
                id="input-decantnumber"
                ref="input-decantnumber"
                :name="$t('register.form.oResponses.sQuestion_101').toLowerCase()"
                maskType="text"
                mask="XXXXXXXXXX"
                :rules="`${dedupStatus.isDedup ? '' : signupForm.model.oResponses.iQuestion_102 == 1 ? 'required|minDecant:4' : ''}|decantNumber:${invalid_decanter_number}`"
                v-model="signupForm.model.oResponses.sQuestion_101"
                animationType="above"
                errorTrigger="blur"
                :textColor="colorStyle.quaternary"
                :labelColor="colorStyle.quaternary"
                placeholder="Decanter code"
                :backgroundColor="colorStyle.quaternary"
                :showIcons="false"
                :designType="panoplyStyle.inputs.designType"
                :borderColor="colorStyle.quinary"
                :errorColor="colorStyle.error"
                @isValid="(isValid) => validity.decantnumber = isValid"
              >Your decanter also has a code.<br>Could you please type it below?</NMask>
              <br>
              <NTypeformCta data-cy="cta-next" v-if="validity.decantnumber" @click.native="nextSlide()">Ok</NTypeformCta>
            </template>
          </NTypeformSlide>

          <NTypeformSlide :index="9">
            <template> 
              <NCheckbox 
                v-if="!dedupStatus.isOptinDedup"
                data-cy="input-optin"
                id="input-optin"
                name="optin"
                v-model="signupForm.model.oOptins.iOptin_2"
                borderColor="white"
                textColor="white"
                :errorColor="colorStyle.error"
                :checkmark="colorStyle.quinary"
                :checkmarkColor="colorStyle.quinary"
                @isValid="(isValid) => (validity.optin = true)"
              >I agree to be contacted by LOUIS XIII by email as described in our privacy policy.</NCheckbox> 
              <NCheckbox 
                data-cy="input-terms"
                id="input-terms"
                name="terms"
                rules="requiredCheckbox"
                v-model="signupForm.terms"
                borderColor="white"
                textColor="white"
                :errorColor="colorStyle.error"
                :checkmark="colorStyle.quinary"
                :checkmarkColor="colorStyle.quinary"
              >I acknowledge having read and accepted settlement of the transaction.*</NCheckbox> 
              
              <span class="table m-auto">
                <NCtaLoader
                  ref="myCtaLoaderRegister"
                  class="my-cta-loader"
                  :loaderDuration="5000"
                  :borderColor="{ default: colorStyle.quinary, hover: colorStyle.quinary, success: colorStyle.quinary, error: colorStyle.error }"
                  :background-color="{ default: 'transparent', hover: 'transparent', success: colorStyle.quinary, error: colorStyle.error }"
                  @click.native="handleSubmit(submit)"
                >
                  REGISTER
                </NCtaLoader>
              </span> 
            </template>
          </NTypeformSlide>
        </template>

        <template v-slot:navigation>
          <NTypeformNavigation
            :currentSlide="currentSlide"
            :requiredSlides="slides ? slides.length : 0"
            :totalSlides="slides ? slides.length + 1 : 0"
            :completedSlides="Object.values(validity).filter((valid) => valid).length"
            :nextEnabled="validity[slides[currentSlide].formName]"
            backgroundColor="#E8CEA4"
            @next="nextSlide"
            @prev="prevSlide"
          >‎</NTypeformNavigation>
        </template>
      </NTypeform>

      <ValidationObserver
        class="py-4 mx-auto"
        v-slot="{handleSubmit}"
        ref="relogObserver"
        tag="form"
        @submit.prevent
        v-if="displayRelog" >
        <div v-if="!relogEmailSent">
          <!-- EMAIL -->
          <NText
            :name="$t('register.form.email')"
            type="email"
            v-model="relogForm.model.sEmail"
            rules="required|email"
            animationType="above"
            errorTrigger="blur"
            :textColor="colorStyle.quaternary"
            :labelColor="colorStyle.quaternary"
            placeholder="jean@gmail.com"
            :backgroundColor="colorStyle.quaternary"
            :showIcons="false"
            :designType="panoplyStyle.inputs.designType"
            :borderColor="colorStyle.quinary"
            :errorColor="colorStyle.error"
            data-cy="input-email-relog"
          >{{$t('register.form.email')}}*</NText>

          <!-- SUBMIT -->
          <span class="table m-auto">
            <NCta
              :borderRadius="'0px'"
              :backgroundColor="colorStyle.primary"
              @click.native="handleSubmit(submitRelog)"
              :disabled="is_loading"
              ref="form_submit_button"
              data-cy="cta-relog"
            >VALIDATE</NCta>
          </span>
        </div>
        <div v-else>
          <p class="text-center mb-20" data-cy="success-message-relog">{{$t('register.relog.description')}}</p>
          <div class="text-center">
            <NCta
              :borderRadius="'0px'"
              :backgroundColor="colorStyle.primary"
              class="mx-auto"
              designType="box"
              @click.native="
                $emit('update:relogEmailSent', false);
                relogForm.model.sEmail = '';
              "
              :disabled="is_loading"
              ref="form_submit_button"
              data-cy="cta-relog-resend"
            >{{$t('register.relog.resend')}}</NCta>
          </div>
        </div>
      </ValidationObserver>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  NRadios,
  NText,
  NMask,
  NCheckbox
} from '@team-uep/n-inputs'

// import { NSelect, NSelectOption } from '@team-uep/n-select';

import {
  NTypeform,
  NTypeformSlide,
  NTypeformCta,
  NTypeformNavigation
} from "@team-uep/n-typeform";

import { mapGetters } from 'vuex'
import registerForm from '../mixins/registerForm'
import design from '@/validators/design'
import NCtaLoader from '@/components/NCtaLoader.vue'

export default {
  name: 'RegisterTypeForm',
  mixins: [registerForm],
  components: { 
    NRadios,
    NText,
    NMask,
    NCheckbox,
    // NSelect,
    // NSelectOption,
    NTypeform,
    NTypeformNavigation,
    NTypeformSlide,
    NTypeformCta,
    NCtaLoader
  },
  props: {
    relogEmailSent: Boolean,
    displayRelog: Boolean,
    is_loading: Boolean,
    user_already_exist: Boolean,
    invalid_decanter_number: Boolean
  },
  data () {
    return {
      colorStyle: design.colors,
      panoplyStyle: design.panoply,
      displayDecanterError: false,
      countryList: [],
      selectedOption: null,
      signupForm: {
        optin: 0,
        terms: 0,
        model: {
          iCivilId: '',
          sEmail: '',
          iZipcode: '',
          sLastName: '',
          sFirstName: '',
          oResponses: {
            sQuestion_101: '',
            iQuestion_102: 0,
            iQuestion_103: 0
          },
          oOptins: {
            iOptin_2: ''
          }
        }
      },
      relogForm: {
        model: {}
      },
      currentSlide: 0,
      slides: [
        { formName: "email", trackingName: "email" },
        { formName: "gender", trackingName: "genre" },
        { formName: "firstname", trackingName: "prenom" },
        { formName: "lastname", trackingName: "nom" },
        { formName: "country", trackingName: "pays" },
        { formName: "zipcode", trackingName: "code postal" },
        { formName: "decant", trackingName: "carafe" },
        { formName: "decantnumber", trackingName: "code carafe" },
        { formName: "terms", trackingName: "opt-in" }
      ],
      validity: {
        email: false, //1
        gender: false, //2
        firstName: false, //3
        lastname: false, //4
        country: false, //5
        zipcode: false, // 6
        decant: false, // 6
        decantnumber: false, // 8
        optin: false, // 9
      }
    }
  },
  computed: {
    ...mapGetters({
      stateParams: 'user/stateParams'
    }),
    getPostalCodeRule () {
      if (this.signupForm.model.oResponses.iQuestion_103 == 230) { // United States rule
        return this.dedupStatus.isDedup ? '' : 'required|min:6'
      } else {
        return this.dedupStatus.isDedup ? '' : 'required|min:3'
      }
    }
  },
  watch: {
    "user_already_exist" : function (val) {
      if ( val ) {
        const emailSlide = this.$refs['emailSlide']; 
        this.goSlide(emailSlide.$attrs.index - 1)
      }
    },
    "invalid_decanter_number" : function (val) {
      if ( val ) {
        const decantNumberSlide = this.$refs['decantNumberSlide'];
        this.goSlide(decantNumberSlide.$attrs.index - 1)
      }
    },
    "signupForm.model.oOptins.iOptin_2" : function (val) {
      if (val === true) {
        this.signupForm.model.oOptins.iOptin_2 = 1
      } else if (val === false) {
        this.signupForm.model.oOptins.iOptin_2 = 0
      }
    }
  },
  mounted() { 
    // URL params prefill on register form
    const urlParams = this.stateParams || this.$route.query;
    const inputEmailRef = this.$refs['input-email'];
    const inputDecantNumberRef = this.$refs['input-decantnumber'];

    urlParams.e != null ? this.signupForm.model.sEmail = urlParams.e : null
    urlParams.p != null ? this.signupForm.model.sFirstName = urlParams.p : null
    urlParams.n != null ? this.signupForm.model.sLastName = urlParams.n : null
    urlParams.c != null && (urlParams.c === '1' || urlParams.c === '2') ? this.signupForm.model.iCivilId = parseInt(urlParams.c) : null

    // GET countries
    this.$requestAPI.countries()
      .then(response => {
        // Format countryList
        const countryList = response.data.data[0]
        Object.keys(countryList).forEach(id => {
          // this.countryList.push({code: id, label: countryList[id] })
          this.countryList.push({label: countryList[id], code: id })
        })
        console.log('countryList', this.countryList)
      })

    // Disable email already used error message on email-input focus
    if (inputEmailRef && inputEmailRef.$el) {
      const inputEmailRefNode = (inputEmailRef.$el.getElementsByTagName('input') || [])[0];

      if (inputEmailRefNode) {
        inputEmailRefNode.onfocus = () => {
          this.$emit('update:user_already_exist', false);
        }
      }
    } 

    if (inputDecantNumberRef && inputDecantNumberRef.$el) {
      const inputDecantNumberRefNode = (inputDecantNumberRef.$el.getElementsByTagName('input') || [])[0];
      if (inputDecantNumberRefNode) {
        inputDecantNumberRefNode.onfocus = () => {
          this.$emit('update:invalid_decanter_number', false);
        }
      }
    }
  },

  destroyed() {
    window.removeEventListener("keyup", this.pressEnter, false);
  },
  methods: {
    pressEnter(e) {
      if (e.keyCode === 9) {
        this.nextSlide();
      }
    },
    checkSlideAuth(num) {
      const next = Math.min(this.slides.length - 1, Math.max(0, num));
      for (let i = 0; i < next; i++) {
        const slideName = this.slides[i].formName;
        console.log('slideName', slideName)
        console.log('this.validity[slideName]', this.validity[slideName])
        if (!this.validity[slideName]) {
          return false;
        }
      }
      console.log('tuutut')
      return true;
    },
    goSlide(num) {
      console.log('nuun', num)
      if (this.checkSlideAuth(num) && num <= this.slides.length) {
        this.currentSlide = num;
      } else {
        var form = this.$children[0].$children[0].$children[0]
        var elem = form.$children[this.currentSlide].$children[0]
        if(elem.$refs.inputField) {
          console.log('this.currentSlide', this.currentSlide)
          if (this.currentSlide >= 3 || (this.dedupStatus.isDedup && this.currentSlide >= 1)) {
            window.setTimeout(() => {
              elem.$refs.inputField.$el && elem.$refs.inputField.$el.focus()
            }, 550)
          } else {
            window.setTimeout(() => {
              elem.$refs.inputField.$el && elem.$refs.inputField.focus()
            }, 550)
          }
        }
      }
    },
    prevSlide() {
      if (this.dedupStatus.isDedup && this.currentSlide === 7) {
        this.goSlide(this.currentSlide - 7);
      }
      // Skip decant number ?
      else if (this.slides[this.currentSlide - 1].formName == 'decantnumber' && this.signupForm.model.oResponses.iQuestion_102 == 2) {
        this.validity['decantnumber'] = true
        this.goSlide(this.currentSlide - 2);
      } else {
        this.goSlide(this.currentSlide - 1);
      }
    },
    nextSlide() {
      console.log('nextSlide', this.slides[this.currentSlide])
      this.$gtmTracking.signupStep(this.slides[this.currentSlide].trackingName)
      if (this.dedupStatus.isDedup && this.currentSlide === 0) {
        this.validity['decant'] = true
        this.goSlide(6); // decant 
      } else {
        this.goSlide(this.currentSlide + 1);
      }
    },
    nextSlideCountry() {
      console.log('this.slides[this.currentSlide]', this.slides[this.currentSlide])
      this.validity.country = true
      this.$gtmTracking.signupStep(this.slides[this.currentSlide].trackingName)
      this.goSlide(this.currentSlide + 1);
    },
    nextSlideDecant() {
      console.log('nextSlideDecant', this.slides[this.currentSlide])
      this.$gtmTracking.signupStep(this.slides[this.currentSlide].trackingName)
      if (this.signupForm.model.oResponses.iQuestion_102 == 1) {
        this.goSlide(this.currentSlide + 1)
      } else {
        this.validity['decantnumber'] = true
        this.goSlide(this.currentSlide + 2)
      }
    },
    nextSlideDedup() {
      console.log('nextSlideDedup', this.slides[this.currentSlide])
      this.$gtmTracking.signupStep(this.slides[this.currentSlide].trackingName)
      this.dedupEmail()
        .then(() => {
          if (this.dedupStatus.isDedup && this.currentSlide === 0) {
            this.validity['gender'] = true
            this.validity['firstname'] = true
            this.validity['lastname'] = true
            this.validity['country'] = true
            this.validity['zipcode'] = true
            this.goSlide(6); // decant 
          } else {
            this.goSlide(this.currentSlide + 1)
          }
        })
    },

    sub () {
      console.log('sub')
      this.$refs.myCta.startLoading()
    },
    submit () {
      console.log('subb')
      this.$gtmTracking.signupStep('opt-in')
      this.$emit('submit', this.signupForm);
    },
    submitRelog () {
      this.$emit('submitRelog', this.relogForm);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .slide-container {
  height: 240px !important;
}
::v-deep .slide.is-state-next-1 {
  opacity: 0;
}
::v-deep .n-input.n-text,
.n-input.n-radios,
.n-input.n-checkbox {
  // reset property
  @apply py-3 text-md min-h-0;
}

::v-deep .n-input.n-input-box div input {
  border-radius: 0px !important;
  background-color: #0A0A0A !important;
  font-size: 16px;
  padding: .7em .8em .7em .8em
}
::v-deep .v-select {
  border: 1px solid white;
  color: white;
  height: 48px;
  > div {
    height: 48px;
  }
  .vs__selected {
    color: white;
  }
  .vs__clear, svg {
    fill: white;
  }
}
.n-select.design-box + .n-typeform-cta {
  margin-top: 20px;
}
::v-deep .n-select.design-box {
  font-family: "Montserrat";
  font-size: 16px;
};
::v-deep .n-select.design-box .label.active {
  color: white;
}
::v-deep .n-select.design-box .input .text {
  color: white;
}
::v-deep .n-select.design-box .label {
  color: white;
  padding-bottom: 15px;
}
::v-deep .n-select.design-box .n-select-ul {
  height: 150px;
}
::v-deep .n-seleect .n-select-option .n-select-option-button[data-v-54832d93]:focus, ::v-deep .n-select .n-select-option .n-select-option-button[data-v-54832d93]:hover {
  background-color: #292828;
}
::v-deep .n-select.design-box .n-select-option {
  background-color: #0A0A0A;
  color: white;
}
::v-deep .n-select.design-box .input {
  border: 1px white solid !important;
  border-radius: 0px !important;
  background-color: #0A0A0A !important;
  font-size: 16px;
  padding: .7em .8em .7em .8em
}
::v-deep .n-input.n-input-box .error input:not(:focus) {
  border-color:#FF3B30 !important;
}
.n-input__radio-label ::v-deep{
  padding-right: 25px;
}
::v-deep .n-input__container label.n-input__label-text {
  margin-bottom: 25px;
  font-size: 16px;
}

::v-deep .n-radios__label-text {
  margin-bottom: 25px !important;
  font-size: 16px !important;
}

::v-deep .n-input__radio {
  border-color: white !important;
}
::v-deep .n-input.n-checkbox {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep .n-input .n-input__checkbox .n-input__checkmark {
  height: 30px;
  min-width: 30px;
  width: 30px;
  margin-top: 12px;
}
::v-deep .n-input .n-input__checkmark-label {
  margin-left: 1.5em;
}
::v-deep .ncta-loader {
  width: 200px;
  button {
    border-width: 1px;
    border-style: solid;
    padding: 0.7em;
  }
}
::v-deep .navigation {
  @media screen and (min-width: 1200px) {
    position: absolute;
    bottom: 20px;
    right: 0;
  }
}
::v-deep .navigation .progress-bar .text {
  font-family: 'Montserrat';
  font-size: 12px;
}
::v-deep .navigation .actions-container {
  align-items: flex-end;
}
::v-deep .navigation .navigation-buttons {
  transform: translateY(7px);
}
::v-deep .navigation .navigation-buttons .button__img {
  border-color: white;
}
::v-deep .navigation .progress-bar .bar {
  background-color: #3B3B3B;
}
::v-deep .n-typeform-cta {
  color: #0A0A0A;
  background-color: #E8CEA4;
  border-radius: 0;
  .valid-icon {
    fill: #0A0A0A;
  }
}

::v-deep .my-cta-loader {
  width: 198px;
  font-size: 14px;
  position: relative;
  .n-cta-loader {
    padding: 0.8em 1.3em;
    &.is-loading:not(.is-completed) .n-cta-loader__progress-bar {
      background-color: rgb(232, 206, 164) !important;
    }
    &.is-loading:not(.is-completed) .n-cta-loader__content {
      color: black;
    }
  }
  .error-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    bottom: -25px;
  }
  .is-completed.error {
    border-color: rgb(255, 59, 48) !important;
  }
}

.n-checkbox ::v-deep{
  .n-input__checkbox {
    height: 26px;
  }
  .n-input__error-text {
    text-align: center;
  }
}

form {
  max-width: 320px;
}
</style>
