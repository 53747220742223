<template>
  <div class="register-container relative md:flex md:flex-col">
    <register-type-form
      ref="registerTypeForm"
      class="typeform-container mx-auto md:flex-1"
      data-cy="register-type-form"
      @submit="postRegister"
      @submitRelog="submitRelog"
      :relog-email-sent.sync="relogEmailSent"
      :displayRelog="displayRelog"
      :is_loading="is_loading" 
      @update:user_already_exist="user_already_exist = $event"
      :user_already_exist="user_already_exist"
      @update:invalid_decanter_number="invalid_decanter_number = $event"
      :invalid_decanter_number="invalid_decanter_number"
    />
    <a
      @click="displayRelog = !displayRelog"
      data-cy="cta-already-register" 
      class="table text-sm pb-5 mx-auto mt-6 md:mt-0 text-center underline cursor-pointer"
      href="#form"
      v-html="
        displayRelog
          ? $t('register.notRegistered')
          : $t('register.alreadyRegistered')
      "
    ></a>
    <SiteFooter></SiteFooter>
    <div id="captcha">
      <vue-recaptcha
        ref="recaptcha"
        id="captcha_id"
        sitekey="6Lf0uhwUAAAAAAZQK0AfIcp6wMgQNO_EOoFg9zND"
        @verify="onVerify"
        :loadRecaptchaScript="true"
        size="invisible"
      ></vue-recaptcha>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { removeSpaces, cleanNumber } from '@/helpers'
  import design from '@/validators/design'
  import RegisterTypeForm from '@/components/RegisterTypeForm.vue' // comment if you want to use a simple form

  import VueRecaptcha from 'vue-recaptcha'
  export default {
    name: 'register',
    data () {
      return {
        design: design,
        displayRelog: false,
        relogEmailSent: false,
        user_already_exist: false,
        invalid_decanter_number: false,
        is_loading: false,
        suggestions: [],
        needsSuggestions: false,
        blocked: false
      }
    },
    components: {  
      VueRecaptcha, 
      RegisterTypeForm // uncomment if you want to use a simple form
    },
    methods: {
      // ...mapActions(['updateUserSession']),
      submitRelog (relogForm) {
        this.$requestAPI.sendRelogEmail({
          sEmail: relogForm.model.sEmail,
        })
          .catch(console.log)
          .finally(() => {
            this.relogEmailSent = true
            this.is_loading = false
          });
      },
      postRegister (signupForm) {
        if (!this.is_loading) {
          this.is_loading = true

          this.$refs.registerTypeForm.$refs.myCtaLoaderRegister.startLoading()
          
          // In case we need to update the data before sending them
          let userData = JSON.parse(JSON.stringify(signupForm.model));
          // Clean data before calling the API
          userData.sFirstName = removeSpaces(userData.sFirstName)
          userData.sLastName = removeSpaces(userData.sLastName)
          userData.iZipcode = cleanNumber(userData.iZipcode)
          
          if (userData.oResponses.iQuestion_102 == 1) {
            userData.oResponses.sQuestion_101 = cleanNumber(userData.oResponses.sQuestion_101).toUpperCase()
          } else {
            delete userData.oResponses.sQuestion_101
          }
          userData.oResponses.iQuestion_102 == 2 ? userData.oResponses.iQuestion_102 = 0 : null
          if (!userData.oOptins.iOptin_2) {
            delete userData.oOptins.iOptin_2
          }

          // Convert country
          if (userData.oResponses.iQuestion_103) {
            userData.oResponses.iQuestion_103 = parseInt(userData.oResponses.iQuestion_103)
          }
          if (this.$refs.registerTypeForm.dedupStatus.isDedup) {
            delete userData.oResponses.iQuestion_103
            delete userData.iCivilId
            delete userData.iZipcode
            delete userData.sFirstName
            delete userData.sLastName
          }
  
          this.$requestAPI.register(userData)
            .then(() => {
              this.$refs.registerTypeForm.$refs.myCtaLoaderRegister.endLoading(true)
              .then(() => {
                this.$mmTro.conversion(this.$route.query)
  
                // vuepress@kwanko-confirm
                // Affiliate pixel (Kwanko) only if idfrom = 15 and user is optin
                const kwankoEnabled = !!this.$store.state.config.activeDecli.config.kwanko.mclic
                const isOptin = userData.oOptins.iOptin_2
                const isFromKwanko = Number(this.$store.getters['global/fromId']) === this.$store.state.config.activeDecli.config.kwanko.idFrom
                if (kwankoEnabled && isOptin && isFromKwanko) {
                  const idu = this.$store.getters['user/idu']
                  this.$kwanko.conversion(userData.sEmail, idu)
                }
                // vuepress@kwanko-confirm
  
                this.$requestAPI.getSessionCurrent()
                  .then(() => {
                    this.$gtmTracking.signupCompleted()
                    this.$router
                      .push({ name: this.$route.meta.nextPage })
                      .catch(console.log);
                  })
                  .catch(console.log);
              })
            })
            .catch((error) => {
              // Test if error is an ApiError object
              this.$refs.registerTypeForm.$refs.myCtaLoaderRegister.endLoading(false)
                .then(() => {
                  if (error.name === 'ApiError') {
                    if (error.response && error.response.status === 503) {
                      const onRecaptcha = () => {
                        this.$off("recaptcha-valid", onRecaptcha);
                        this.postRegister(signupForm);
                      }
  
                      // trick to keep signupForm in cache
                      // create a listenner on recaptcha 
                      this.$on("recaptcha-valid", onRecaptcha);
  
                      this.$refs.recaptcha.reset()
                      this.$refs.recaptcha.execute()
                    }
        
                    if (error.response && error.response.status === 409) {
                      this.user_already_exist = true
                    }
                    if (error.response && error.response.status === 400) {
                      this.invalid_decanter_number = true
                    }
                  }
                  this.is_loading = false
                  setTimeout(() => {
                    this.$refs.registerTypeForm.$refs.myCtaLoaderRegister.reset()
                  }, 2000)
                });
            })
        }
      },

      onVerify: function (response) {
        console.log('Verify: ' + response)
        this.sendCaptchaResult(response)
      },

      sendCaptchaResult (result) {
        this.$requestAPI.sendCaptchaToken({
          sBlockedUrl: [
            `${document.location.protocol}//${document.location.host}/api/v0/`,
            `${this.appConfig.baseName}/${this.appConfig.activeDecli.apiVersion}`,
            `/inscription/register`
          ].join(''),
          sCaptchaResponse: result,
        })
          .then(() => {
            this.$emit('recaptcha-valid')
          })
          .catch(console.log);
      }
    },
    computed: {
      ...mapGetters({
        appConfig: 'config/appConfig'
      }),
    },
    mounted () {
      this.$gtmTracking.pageView()
      this.$mmTro.sendRtg()
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .footer-container {
  margin-top: 0 !important;
  .textFooter {
    position: absolute;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    padding-bottom: 20px;
  }
}
::v-deep .typeform {
  .n-typeform-cta {
    display: flex;
  }
  .valid-icon {
    margin: auto;
    margin-left: 0.5rem;
  }
  .n-input input {
    padding-top: Orem;
  }
}
.register-container {
  height: 100vh;
  background: url('../assets/bg/register.jpg');
  background-position: center center;
  background-size: cover;
  .typeform-container {
    width: 320px;
    padding-top: 120px;
    @media screen and (min-width: 768px){
      padding-top: 270px;
    }
  }
}
#captcha {
  visibility: hidden;
}
</style> 
